.description-text-container {
  position: relative;
  border-radius: 6px;
  padding: 12px;
  width: 100%;
  background-color: var(--primary-background-light);
  color: black;
}

.toggle-view {
  position: absolute;
  bottom: 12px;
  right: 16px;
  color: var(--primary-background-darker);
  background-color: var(--primary-background-light);
  text-decoration: none;
  cursor: pointer;
  color: var(--primary-background-darker);
  font-weight: 500;
  padding-left: 10px;
}
.description-text {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  flex: 1; /* Allow the text to take up available space */
}

.description-text.expanded {
  -webkit-line-clamp: unset; /* Remove clamping in expanded view */
  overflow: visible;
}
