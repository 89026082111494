.superadmin-logos-img {
  cursor: pointer;
  opacity: 1;
  -webkit-transition: opacity 0.35s ease;
  -moz-transition: opacity 0.35s ease;
  -o-transition: opacity 0.35s ease;
  transition: opacity 0.35s ease;
  z-index: 2;
}

.superadmin-logos-img:hover {
  opacity: 0.3;
}

.superadmin-logo-container {
  position: relative;
}

.superadmin-logos-img-hover {
  z-index: 1;
  width: 100%;
  color: #000;
  text-align: center;
  text-decoration: none;
  font-family: verdana;
  position: absolute;
  bottom: 50%;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.superadmin-logo-container:hover superadmin-logos-img-hover {
  opacity: 1;
  z-index: 3;
}

.superadmin-logos-table .ant-table-thead > tr > th {
  background-color: black;
}

.superadmin-logos-table .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: black;
}
