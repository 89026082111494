.orders-actions-container {
  background-color: white;
  padding: 1rem 2rem;
  /* border-radius: 10px 10px 0px 0px; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 5rem;
  justify-content: space-between;
}

.orders-filter-container {
  margin-top: 2px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}

.orders-action-buttons {
  display: flex;
  gap: 1rem;
}

.orders-dropdown-filters {
  display: flex;
  gap: 1rem;
}

@media (min-width: 768px) {
  .orders-actions-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
