.accordion {
  border-radius: 10px;
  padding: 16px;
  transition: height 0.25s;
}

.accordion--collapsed {
  border: 1px solid #e7e8e8;
  height: 48px;
  cursor: pointer;
  transition: border 0.2s;
}

.accordion--collapsed:hover {
  border: 1px solid #cbcbcb;
}

.accordion--expanded {
  background-color: #f1f2f2;
  border: 1px solid transparent;
  height: auto;
}

.accordion-expand-icon {
  cursor: pointer;
  transition: transform 0.25s;
}

.accordion-expand-icon--expanded {
  transform: rotate(-180deg);
}

.accordion-expand-icon--minimized {
  transform: rotate(0deg);
}

.accordion-title {
  font-weight: 700;
  font-size: "16px";
  margin-bottom: 0;
}

.accordion-title--expanded {
  color: #666666;
}
