.video-responsive {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  /* margin-left: 16px; */
  margin-top: 1rem;
  border-radius: 16px;
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; /* Remove border */
}
