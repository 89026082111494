.purchases-container {
  padding: 12px;
}

.purchases-description {
  background-color: white;
  padding: 12px;
  margin-top: 2px;
}

.purchase-filter {
  display: flex;
  align-items: center;
}

.purchases-filter-container {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  margin: 0;
  background-color: var(--primary-text-color);
  /* border-radius: 0px 0px 10px 10px; */
  /* border-radius: 10px; */
  padding: 0rem 2rem;
}

.purchase-filters-header {
  background-color: white;
  padding: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .purchase-filter {
    display: flex;
    flex-direction: column;
  }

  .purchases-filter-container {
    border-radius: 0px 0px 10px 10px;
  }

  .purchases-container {
    padding: 0.5rem;
  }
}
