.get-started-maximized-container {
  display: flex;
  padding: 16px;
  gap: 24px;
  position: absolute;
  bottom: calc(60px + 1rem);
  right: 0;
  opacity: 1;
  background-color: white;
  border-radius: 16px;
  border: 6px solid var(--primary-background);
  width: 400px;
  transition:
    opacity 0.25s ease-in-out,
    bottom 0.25s ease-in-out,
    width 0.25s ease-in-out;
  box-shadow: 0px 4px 12px 0px #0000004d;
}

.get-started-maximized-container--detailed {
  width: 950px;
}

.get-started-maximized {
  /* max-width: 400px; */

  min-width: 350px;
}

.maximized-view-details {
  min-width: 500px;
  max-width: 500px;
  padding-right: 10px;
}

.get-started-maximized-container--hidden {
  opacity: 0;
  bottom: -50px;
  pointer-events: none;
}

.get-started-maximized-header {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.get-started-maximized-tasks {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 350px;
  overflow-y: auto;
  padding: 1px;
}

.divider {
  width: 1px;
  background-color: #e3e3e3;
}
