.members-actions-container {
  background-color: white;
  padding: 1rem 2rem;
  /* border-radius: 10px 10px 0px 0px; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 5rem;
}

.members-actions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.members-actions-search-container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .members-actions-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .members-actions-search-container {
    flex-direction: row;
    align-items: center;
  }
}

.member-actions-buttons {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
}
