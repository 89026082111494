.members-table-container {
  padding: 12px;
}

.members-action-bar-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  /* margin-bottom: 1rem; */
}

.family-tooltip .ant-tooltip-inner {
  background-color: red !important;
}

.member-tags {
  display: flex;
  align-items: top;
  height: fit-content;
  gap: 6px;
}

@media screen and (max-width: 767px) {
  .members-table-container {
    padding: 0.5rem;
  }
}
