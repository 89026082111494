.header-navbar {
  width: 100%;
  background-color: var(--primary-background);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0 24px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header-container {
  /* max-width: 1200px; */
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px;
  padding: 0;
}

.logo {
  height: 32px;
  width: auto;
}

.group-selector {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.group-icon {
  width: 20px;
  height: 20px;
}

.dropdown-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.dropdown-button:hover {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 160px;
  display: none;
}

.dropdown-content.show {
  display: block;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

ul li:hover {
  color: gray;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .header-navbar {
    padding: 0 16px;
  }

  /* .header-container {
    height: 56px;
  } */

  .logo {
    height: 28px;
  }
}
