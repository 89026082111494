.react-chip-input-text {
  border: none;
  outline: none;
}

.react-chip-input-text:focus {
  outline: none;
}

.react-chip-input-chip {
  background-color: rgb(221, 221, 221);
  border-radius: 10px;

  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.react-chip-input-chip-remove {
  /* background-color: white;
  border: 1px solid black;
  border-radius: 50%; */
  cursor: pointer;
}

.react-chip-input-chips-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
