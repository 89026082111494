.maximized-view-content-details {
  max-height: 400px;
  overflow: auto;
}

.maximized-view-back-button {
  transition: transform 0.2s ease-in-out;
}
.maximized-view-back-button:hover {
  transform: scale(1.2);
}

.maximized-view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-left: -8px;
}

.maximized-view-header-mark-complete {
  display: flex;
  gap: 10px;
}

.maximized-view-header-mark-complete p {
  margin-bottom: 0;
  font-size: 14px;
  color: #6a6a6a;
  font-weight: 600;
}

.maximized-view-details-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #65a30d;
  border-color: #65a30d;
}
.maximized-view-details-checkbox
  .ant-checkbox-wrapper:hover
  .ant-checkbox-inner,
.maximized-view-details-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.maximized-view-details-checkbox
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: #65a30d !important;
}

.maximized-view-details-checkbox
  .ant-checkbox-indeterminate
  .ant-checkbox-inner::after {
  background-color: #65a30d;
}
