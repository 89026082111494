.members-filters-container {
  background-color: white;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}

.members-filters {
  background-color: white;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.member-filter-input {
  width: 200px;
}

.member-filter-input--chapters {
  width: 150px;
}

.member-filters-header {
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* border-radius: 0px 0px 10px 10px; */
}
