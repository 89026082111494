.stadium-setup-footer-buttons {
  display: flex;
  gap: 16px;
}

.access-qr-container {
  display: flex;
}

@media screen and (max-width: 767px) {
  .stadium-setup-footer-buttons {
    display: block;
  }

  .access-qr-container {
    display: flex;

    flex-wrap: wrap;
  }
}
