.ant-steps-item-icon {
  background-color: #4caf50; /* Green background */
}

/* Change the color of the active step title */
.ant-steps-item-process .ant-steps-item-title {
  color: #4caf50; /* Green text for active step */
}

/* Change the color of the completed steps */
.ant-steps-item-finish .ant-steps-item-title {
  color: #4caf50; /* Green text for completed steps */
}

/* Change the color of the step description */
.ant-steps-item-description {
  color: #888; /* Gray description text */
}
