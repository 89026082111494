.screen-header-container {
  background-color: white;
  padding: 12px;
  /* border-radius: 10px 10px 0px 0px; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 5rem;
}

.screen-header-actions-container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .screen-header-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .screen-header-actions-container {
    flex-direction: row;
    align-items: center;
  }
}
