.group-dropdown-name--singular {
  font-size: 24px;
  font-weight: 400;
  color: var(--primary-text-color);
  pointer-events: none;
}

.group-dropdown-name > .ant-space-item:first-child {
  font-size: 24px;
  font-weight: 400;
  color: var(--primary-text-color);
}

.group-dropdown-name > .ant-space-item:last-child {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-text-color);
}

.group-dropdown-name > .ant-space-item:last-child:hover {
  transform: translate(-90deg);
}
