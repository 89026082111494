/* Custom styles for Ant Design Sidebar */
/* Sidebar background color */
.ant-layout-sider {
  background: var(--sidenav-trigger-bg-color) !important;
}

/* Menu background color */
.ant-menu.ant-menu-dark {
  background: var(--sidenav-trigger-bg-color);
  color: var(--primary-text-color) !important;
}

/* .ant-menu-title-content {
  color: var(--primary-text-color) !important;
} */

/* .ant-menu-dark .ant-menu-item {
  transition: all 0.1s ease-in-out !important;
} */

.ant-menu-dark .ant-menu-item-selected {
  background-color: var(--primary-text-color) !important;
  color: var(--primary-background) !important;
  font-weight: bold !important;
  margin-bottom: 50px;
  /* transition:
    font-weight 0.1s ease-in-out,
    background-color 0.1s ease-in-out,
    color 0.1s ease-in-out !important; */
}

.ant-menu-item-only-child {
  font-size: 14px;
  color: var(--primary-text-color);
  font-weight: 400;
}

.ant-menu-dark .ant-menu-item .anticon,
.ant-menu-dark .ant-menu-submenu-title {
  padding-left: 24px;
  margin: 0;
  border-radius: 0;
  width: 100%;
}

/* Menu item icon color */
.ant-menu-dark .ant-menu-item .anticon,
.ant-menu-dark .ant-menu-submenu-title .anticon {
  color: #999999;
}

.ant-menu-submenu-title .ant-menu-submenu-arrow {
  transform: scale(1.2) !important;
}

/* Submenu background */
.ant-menu-dark .ant-menu-sub {
  background: var(--primary-background-dark) !important;
}

.ant-menu-dark .ant-menu-sub .ant-menu-item-only-child {
  padding-left: 12px !important;
  margin: 0 !important;
  border-radius: 4px !important;
  margin-left: 42px !important;
  height: 30px !important;
  width: auto !important;
  color: var(--primary-text-color);
  margin-bottom: 8px !important;
}

/* Collapsed sidebar trigger button */
.ant-layout-sider-trigger {
  background: #34495e;
}

/* Hover state for trigger button */
.ant-layout-sider-trigger:hover {
  background: #2c3e50;
}

.ant-menu-dark .ant-menu-submenu-popup {
  background: #2c3e50;
}

.ant-menu-submenu {
  margin: 8px;
  border-radius: 0px !important;
}

.ant-menu-item {
  margin-left: var(--sidenav-submenu-item-margin) !important;
  border-radius: 0px !important;
}

.side-nav {
  position: fixed;
  overflow: auto;
  height: 100vh;
}

.side-nav .ant-layout-sider-trigger {
  background-color: var(--primary-background-dark);
  /* border-right: 1px solid white !important; */
}

.side-nav .ant-layout-sider-children {
  overflow-y: auto;
  max-height: 77vh;
}

.sidenav-logout-btn {
  position: absolute;
  bottom: 112px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px 0px;
  color: var(--logout-btn-text-color);
  background-color: var(--logout-btn-bg-color);
}

.ant-menu-dark .ant-menu-submenu-popup {
  background: #2c3e50;
}

.ant-menu-submenu-open {
  background-color: var(--primary-background-dark) !important;
}
