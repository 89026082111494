.superadmin-groups-container {
  width: 97vw;
  margin: auto;
}

.create-group-filters {
  background-color: white;
  display: flex;
  padding: 20px;
  border-radius: 12px;
  flex-wrap: wrap;
  /* width: fit-content; */
  margin-bottom: 12px;
}
