.event-filters-container {
  background-color: white;
  /* padding: 1rem 2rem; */
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.event-filters {
  background-color: white;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.event-filter-input {
  width: 200px;
}

.event-filter-input--chapters {
  width: 150px;
}

.event-filters-header {
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* border-radius: 0px 0px 10px 10px; */
}
