.get-started-minimized-text {
  color: white;
  width: 40px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 0;
}

.get-started-minimized {
  /* height: 92px;
  width: 92px; */
  height: 65px;
  width: 65px;
  background-color: var(--primary-background);
  color: var(--primary-text-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-left: auto;
  transition: 0.25s;
  box-shadow: 0px 4px 12px 0px #0000004d;
}

.get-started-minimized:hover {
  transform: scale(110%);
}

.get-started-minimized-text {
  transition: opacity 0.25s;
  position: relative;
}

.get-started-minimized-text--expanded,
.get-started-minimized-text--minimized {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.get-started-minimized-text--expanded.visible,
.get-started-minimized-text--minimized.visible {
  opacity: 1;
}

.get-started-minimized-text--expanded {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.get-started-minimized-text--minimized {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  line-height: 14px;
}
