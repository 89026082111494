.timeline {
  position: relative;
}

.timeline-title {
  font-weight: 600;
  font-size: 14px;
  color: #666666;
  margin-bottom: 0;
}

.timeline-subtitle {
  font-weight: 400;
  font-size: 12px;
  color: #808080;
  margin-bottom: 0;
}

.timeline-element:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 22px;
  left: 12px;
  width: 1px;
  height: calc(100% - 40px);
  background: linear-gradient(to bottom, #737373 50%, transparent 0%) repeat-y;
  background-size: 2px 8px;
  z-index: 0;
}

.timeline-title {
  transition: 0.2s ease-in-out;
}

.timeline-title:hover {
  transform: scale(1.01);
}

.timeline-title--complete {
  color: #666666;
}

.timeline-title--complete:hover {
  color: #0d0d0d;
}

.timeline-title--incomplete {
  color: #0d0d0d;
}

.timeline-title--incomplete:hover {
  color: #000000;
}

.timeline-arrow {
  transition: 0.2s;
}
.timeline-arrow:hover {
  filter: invert(0%) sepia(0%) saturate(7469%) hue-rotate(217deg)
    brightness(108%) contrast(108%);
  transform: scale(120%);
}
