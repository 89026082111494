.directory-highlight-statement-input {
  width: 66.66%;
  margin-left: 18px;
  display: flex;
  gap: 10px;
}

.directory-highlight-statement {
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 767px) {
  .directory-highlight-statement-input {
    width: 100%;
    display: block;
  }

  .directory-highlight-statement {
    display: flex;

    flex-wrap: wrap;
  }
}
