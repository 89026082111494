.phone-number-input-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 12px;
  margin-bottom: 6px;
}

.phone-number-input-field {
  background: transparent;
  border: 2px solid #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  padding: 5px 10px;
}

.phone-number-input-dropdown {
  border: 0 none transparent;
  background: #2f2c32;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.phone-number-input-number {
  background: transparent;
  border: 2px solid #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.phone-number-input-number input {
  background: transparent;
  border: 0;
  outline: none;
  width: 100%;
  color: var(--primary-text-color);
}
