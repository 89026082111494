.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 16px;
  padding: 12px;
}

.grid-item {
  width: max-content;
  cursor: pointer;
  border-radius: 10px;
  transition: scale 0.15s ease;
}

.grid-item:hover {
  border-radius: 10px;
  scale: 1.1;
}

.selected-icon {
  outline: 3px solid rgb(43, 206, 21);
  scale: 1.1;
  transition: all 0.15s ease;
}
