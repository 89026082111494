.getting-started {
  z-index: 100;
  position: fixed;
  bottom: 22px;
  right: 22px;
}

.getting-started--minimized {
  height: 92px;
  width: 92px;
  background-color: var(--primary-background);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
