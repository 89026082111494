.digit-badge-container {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid var(--digit-badge-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.digit-badge-text {
  color: var(--digit-badge-color);
  font-weight: 600;
  font-size: 14px;
}
