:root {
  --main-color: #ff4040;
  --text-color: #ffffff;
  --top-margin: 0px;
  --step-color: transparent;
  --primary-background: rgb(0, 0, 0);
  --primary-background-dark: rgb(0, 0, 0);
  --primary-background-darker: rgb(0, 0, 0);
  --primary-background-light: rgb(0, 0, 0);
  --primary-text-color: rgb(255, 255, 255);
  --primary-gray: #f1f1f1;
}

body {
  font-family: "Nunito";
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.my-row {
  display: flex !important;
  flex-wrap: wrap !important;
  /* margin-right: calc(-0.5 * var(--bs-gutter-x)) !important;
  margin-left: calc(-0.5 * var(--bs-gutter-x)) !important; */
}
.pac-container {
  margin: -142px 0 0 0 !important;
  max-height: 100px;
  overflow: scroll;
}

.logo {
  color: #17a2b8;
  font-weight: 900;
}

.settings {
  color: #ffff;
  font-weight: bold;
  font-size: 16px;
}

.ReactCrop__crop-selection {
  border-radius: 0%;
}

.bg-custom-dark {
  background: #ff4040;
}

.bg-custom-header {
  background: #ff4040;
}

/* .top-fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
} */
/* 
.btn-custom-outline{
    color: #17a2b8;
}

.btn-custom-outline:hover{
    color: white !important;
} */
.container-links {
  display: flex;
  justify-content: space-evenly;
}

.modal-90w {
  max-width: 90%;
}

.color-green {
  color: darkgreen;
}

.color-yellow {
  color: goldenrod;
}

.color-red {
  color: maroon;
}

.cursor-pointer {
  cursor: pointer;
  color: #000000;
  font-weight: 1000;
}

.ant-table-thead .ant-table-cell {
  background-color: var(--primary-gray) !important;
}

/* .a-color-secondary{
    color: rgba(0, 0, 0, 0.575);
}

.a-color-primary{
    color: black;
}

.a-color-primary:hover, .a-color-secondary:hover{
    color: #17a2b8;
}

.a-white{
    color:rgba(255, 255, 255, 0.372);
} */

/* .active{
    color: white
} */

.hue-horizontal {
  margin-bottom: 5px !important;
}

.alpha-picker {
  margin-top: 10px !important;
}

.ant-calendar-picker {
  /* padding: 0px; */
}

.ant-calendar-picker div,
.ant-calendar-picker input,
.ant-time-picker-input,
.ant-calendar-picker-input {
  height: 100%;
}

.ant-calendar-picker input,
.ant-time-picker-input,
.ant-calendar-picker-input,
.css-2b097c-container,
.css-yk16xz-control {
  border: 0px;
  color: #495057;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 0.5;
}

.react-phone-number-input__input {
  border: 0px;
  height: auto;
}

.react-phone-number-input__icon {
  height: auto;
}

.ant-upload-select-picture-card,
.si9 .ant-upload-list-item {
  width: 184px !important;
}

.ant-tooltip {
  max-width: none;
}

/* .ant-table-scroll {
  border-radius: 5px;
} */

/* div.ant-table-body::-webkit-scrollbar {
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
}

div.ant-table-body::-webkit-scrollbar:vertical {
  width: 0px;
}

div.ant-table-body::-webkit-scrollbar:horizontal {
  height: 11px;
}

div.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
} */

.form-title {
  margin-top: 20px;
  position: relative;
  text-align: center;
}

.stylised-button:active {
  outline: none;
  border: none;
}

canvas,
img {
  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
}

.span-link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.span-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.input-height input {
  line-height: 20px;
}

.span-padding-top-0 span {
  padding-top: 0px;
}

.custom-direction-column > div:first-child {
  flex-direction: column;
}

.placeholder-light::placeholder {
  color: #bec2c9;
  font-size: 0.9rem;
  font-weight: 400;
}

.upload-button > .col {
  flex-grow: 0;
}

@media screen and (max-width: 767px) {
  .hide-for-mobile {
    display: none !important;
  }
}

.overlay-check {
  height: 130px;
  width: 170px;
}

.no-arrow-field::-webkit-inner-spin-button,
.no-arrow-field::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.selected-tab {
  border-bottom: 2px solid #ff4040 !important;
}

.website-welcome-radio > .ant-radio-wrapper {
  display: flex;
}

.justify-content-space-between {
  justify-content: space-between;
}

.flex-1 {
  flex-grow: 1;
}

/* .ant-menu-submenu-open > ul {
  background-color: transparent !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  height: 26px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #999999;
  height: 100%;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item > a {
  color: var(--text-color);
  font-size: 16px;
  padding: 0rem 1.5rem;
  line-height: 26px;
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  font-size: 18px;
  height: 30px;
  display: flex;
  align-items: center;
} */

.ant-select-selection {
  background-color: transparent !important;
  border: 0px none transparent;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.ant-select-selection__clear {
  background-color: transparent;
}

/* .ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu-dark .ant-menu-vertical.ant-menu-sub {
  background-color: var(--main-color) !important;
  -webkit-box-shadow: none;
  box-shadow: none;
} */

/* .ant-layout-sider-trigger {
  background-color: #00000045;
} */

.placeholder-white::placeholder {
  color: white;
  opacity: 1;
  /* font-size: 1rem; */
}

.nav-tabs {
  border-bottom: 4px solid #fff;
}

.nav-tabs .nav-item {
  margin-bottom: -4px;
}

/* th.header-white {
  color: var(--primary-text-color) !important;
} */

.header-group-select .ant-select-selection-selected-value {
  font-size: 25px;
  color: var(--primary-text-color);
}

.header-group-select .ant-select-arrow-icon {
  color: white !important;
}

.filters-bottom-0 .form-group {
  margin-bottom: 0px;
}

/* .table-backdrop .ant-table {
  box-shadow: 0px 3px 10px 1px;
} */

.ant-pagination li {
  background-color: transparent;
  border-color: #fff;
}

.ant-pagination li a {
  background-color: transparent;
  border-color: #fff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 25px;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #007bff;
  background-color: #fff;
}

.ant-tag {
  margin: 0;
}

/* .ant-menu-dark.ant-menu-inline .ant-menu-item {
  margin-bottom: 0px;
  margin-top: 0px;
} */

.color-picker-border div {
  border: 1px solid #000000 !important;
  border-radius: 50%;
}

.ant-pagination-item-link {
  display: grid !important;
  align-items: center !important;
  background-color: transparent !important;
  color: #ffffff !important;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  height: 25px;
  width: 25px;
  min-width: 25px;
}

.ant-pagination-item {
  height: 25px;
  width: 25px;
  min-width: 25px;
}

.nav-link {
  /* padding: 0rem 1rem; */
  /* margin-top: var(--top-margin); */
  display: flex;
  align-items: center;
  height: 25px;
}

/* .ant-menu-vertical.ant-menu-sub .ant-menu-item {
  height: 30px !important;
} */

/* .ant-menu-vertical.ant-menu-sub .ant-menu-item:hover{
  background-color: #999999;
} */
/* .ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
} */

.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--step-color);
  border-color: var(--step-color);
}

.button-drop-shadow {
  position: relative;
  box-shadow: 0px 0px 3px 1px var(--main-color);
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
}

.button-drop-shadow::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 10px 40px var(--main-color);
  transition: all 0.5s;
  opacity: 0;
}

.button-drop-shadow:active::after {
  position: absolute;
  box-shadow: 0 0 0 0 white;
  transition: 0s;
  opacity: 1;
  left: 0;
  top: 0;
}

.unauth-header-link {
  padding: 0px 30px;
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
  letter-spacing: -1px;
}

.unauth-header-link a {
  position: relative;
  color: #ffffff;
  transition: color 300ms ease 0ms;
}

.unauth-header-link a:hover {
  color: #ff4040 !important;
  text-decoration: none;
}

.unauth-header-link a::after {
  content: "";
  left: 50%;
  position: absolute;
  bottom: -2px;
  width: 0%;
  border-radius: 3px;
  height: 5px;
  opacity: 0;
  background-color: #ff4040;
  transition: 300ms cubic-bezier(0.25, 0.01, 0.32, 1.61);
}

.unauth-header-link a:hover::after {
  left: -5%;
  width: 110%;
  opacity: 1;
}
.form-group {
  margin-bottom: 0.75rem;
}

.form-group-constrained {
  margin-bottom: 0.5rem;
}

.collapsible-horizontal {
  /* overflow: scroll; */
}

.collapsible-horizontal > * {
  float: left;
  display: block;
  text-align: center;
}

.collapsible-horizontal .icon {
  display: none;
}

/* .ant-select-item-option-content {
  white-space: normal !important;
  word-break: break-word;
  line-height: 1.2em;
  padding: 4px 0;
} */

.collapsible-horizontal > .show-for-mobile {
  display: none;
  padding: 0px 5px 5px;
}

@media screen and (max-width: 1100px) {
  .collapsible-horizontal > *:not(:first-child) {
    display: none !important;
  }

  .collapsible-horizontal.tab-menu {
    position: absolute;
    right: 0;
    top: 0;
  }

  .collapsible-horizontal > *.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 1100px) {
  .collapsible-horizontal.responsive {
    position: relative;
    display: block;
  }

  .collapsible-horizontal.tab-menu.responsive {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
  }

  /* .collapsible-horizontal.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  } */

  .collapsible-horizontal.responsive > * {
    float: none;
    display: block !important;
    text-align: left;
  }

  .collapsible-horizontal.tab-menu.responsive > *:not(:first-child) {
    display: none !important;
  }

  .collapsible-horizontal.tab-menu.responsive > .show-for-mobile {
    float: none;
    display: block !important;
    text-align: left;
    position: relative;
    z-index: 10;
  }

  .collapsible-horizontal.tab-menu.responsive > .show-for-mobile > .menu-list {
    box-shadow: 0px 5px 8px 1px #000000c0;
  }
}

/* .ant-table-thead > tr > th {
  background-color: var(--main-color);
} */

.placeholder-regular::placeholder {
  color: lightslategrey;
}

.custom-disabled .ant-select-selection {
  color: #888888 !important;
}

.default-text-color {
  color: var(--primary-text-color);
}

.default-border {
  border-color: var(--primary-text-color);
}

.promote-form-text-color {
  color: #ffffff;
}

.promote-form-text-14 {
  font-size: 14px;
}

.promote-form-text-16 {
  font-size: 16px;
}

.promote-form-text-18 {
  font-size: 18px;
}

.background-promote {
  background-size: cover !important;
}

.promote-form-editor .rdw-editor-main {
  color: #fff;
}

#promote-details p {
  margin-bottom: 0px !important;
}

.page-break {
  position: relative;
}

.page-break::after {
  position: absolute;
  content: "";
  height: 2px;
  left: 10%;
  right: 10%;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  background-color: #afafaf;
}

.height-fit-content {
  height: fit-content;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
