.ant-themed-button.ant-btn-primary {
  background: var(--primary-background);
  border-color: var(--primary-background);
  color: var(--primary-text-color);
}

.ant-themed-button.ant-btn-primary:not(:disabled):hover {
  background: var(--primary-background-dark);
  border-color: var(--primary-background-dark);
}

.ant-themed-button.ant-btn-primary:not(:disabled):active {
  background: var(--primary-background-dark);
  border-color: var(--primary-background-dark);
}

/* Default Button */
.ant-themed-button.ant-btn-default {
  background: var(--button-default-bg);
  border-color: var(--button-default-border);
  color: var(--button-default-color);
}

.ant-themed-button.ant-btn-default:not(:disabled):hover {
  color: var(--primary-background-dark);
  border-color: var(--primary-background-dark);
}
