.dashboard-admin-link {
  color: #ffffff;
  font-weight: 800;
  font-size: 21px;
  margin-right: 35px;
}

.dashboard-admin-link:hover {
  color: #f0f0f0;
}
