.copy-event-modal .ant-modal-header {
  background-color: var(--copy-modal-header-bg-color);
}

.copy-event-modal .ant-modal-title {
  color: var(--copy-modal-header-text-color);
}

.copy-event-modal .ant-modal-close-x {
  color: var(--copy-modal-header-text-color);
}

.copy-event-modal .ant-modal-body {
  padding: 0;
}

.copy-event-modal-container {
  max-height: 70vh;
  overflow: auto;
}

.copy-event-modal-subtitle {
  padding-left: 24px;
  padding-top: 12px;
  font-size: 16px;
}
