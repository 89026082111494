.icon-container {
  border: 1px solid rgb(204 204 204);
  border-radius: 6px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 30px;
}
