.edit-member-container {
  display: flex;
}

.edit-member-profile-pic {
  width: 72%;
  padding-bottom: 72%;
}

.edit-member-modal .ant-modal-content {
  padding: 0px;
  /* padding-bottom: 20px; */
}

.edit-member-footer {
  display: flex;
  gap: 8px;
  justify-content: end;
  margin-top: 1.5rem;
}

@media screen and (max-width: 767px) {
  .edit-member-container {
    display: flex;
    flex-direction: column;
  }

  .edit-member-profile-pic {
    width: 40%;
    padding-bottom: 40%;
  }

  .edit-member-modal .ant-modal-content {
    padding: 0px;
  }

  .edit-member-footer {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 1rem;
  }
}
